import React from 'react';
import ButtonOutline from "components/ButtonOutline";
import ButtonGroup from "./ButtonGroup";
import InputCheckedList from './InputCheckedList';
import InputRangeSlider from './InputRangeSlider';

import ConstantData from 'data/ConstantData';
import ThemeData from 'data/ThemeData';


import {
	FaCaretDown
} from "react-icons/fa";


import { createUseStyles } from 'react-jss';
import useGlobalUnitsFilter from 'hooks/useGlobalUnitsFilter';
import { useQuery } from '@apollo/client';
import QueryData from 'data/QueryData';
import { InputAdornment } from '@mui/material';
import { filterableSourcesHeaderButtons } from 'data/FilterData';


const useStyles = createUseStyles({
    filterHeaderWrapper: {
        zIndex: 2,
        position: 'relative',
        width: '100%',
        padding: '18px 0 14px 0',
        backgroundColor: ThemeData.colours.primaryBg,
        boxShadow:' 0px 0.5px 0px 0.5px rgba(0, 0, 0, 0.05)'
    },
    filterButtons: {
        flex: '0 0 auto',
        padding: '0 50px',
    },
    filterButton: {
        // ...ThemeData.styles.roundedCorners,
        borderRadius: 5,
        border: '1px solid #EEEDE6'
    },
    filterList: {
        color: ThemeData.colours.black,
    },
    filterRange: {
        color: ThemeData.colours.secondaryFont,
        backgroundColor: ThemeData.colours.white,
    },
});

const FilterHeader = (props) => {
    
    const classes = useStyles();
    const globalUnitsFilter = useGlobalUnitsFilter();
    const { data } = useQuery( QueryData.unitProperties);
    let hooks = {
        globalUnitsFilter
    };

    const checkIsActive = (filter) => {
        if(filter === 'price' || filter === 'size') {
            return hooks.globalUnitsFilter.globalUnitsFilter.filter[filter] && 
            (hooks.globalUnitsFilter.globalUnitsFilter.filter[filter].low !== filterableSourcesHeaderButtons.filter(item => item.unitRelationDataType === filter)[0].rangeMinimumValue
            ||
            hooks.globalUnitsFilter.globalUnitsFilter.filter[filter].high !== filterableSourcesHeaderButtons.filter(item => item.unitRelationDataType === filter)[0].rangeMaximumValue);
        }
        return hooks.globalUnitsFilter.globalUnitsFilter.filter[filter] && Object.keys(hooks.globalUnitsFilter.globalUnitsFilter.filter[filter]).filter((key) => 
            hooks.globalUnitsFilter.globalUnitsFilter.filter[filter][key].doFilterByOr === true
        ).length > 0
    }

    const calculateFilterButtonName = (data, filterLabel, currentLabel, inputType) => {
        const filters = hooks.globalUnitsFilter.globalUnitsFilter.filter;
        const labelArray = filters[filterLabel] && Object.keys(filters[filterLabel]).filter((item) => 
            filters[filterLabel][item].doFilterByOr === true
        );

        const filteredOptions = labelArray && labelArray.length > 0 && data.filter(item => item.id === labelArray[0]);


        if(labelArray && labelArray.length > 1) 
            return `${currentLabel === "Orientation" ? filteredOptions[0].direction : filteredOptions[0].name} and  ${labelArray.length - 1} more`
        
        if(labelArray && labelArray.length === 1)
            return currentLabel === "Orientation" ? filteredOptions[0].direction : filteredOptions[0].name
        
        if(filters[filterLabel] && inputType === ConstantData.unitFilterTypes.range && currentLabel === 'Price')
            return `$${filters[filterLabel].low/1000}K - $${filters[filterLabel].high/1000}K`
        
        if(filters[filterLabel] && inputType === ConstantData.unitFilterTypes.range && currentLabel === 'Size')
            return `${filters[filterLabel].low} sqft - ${filters[filterLabel].high} sqft`
    
        return currentLabel
    }

    
    return (
    <div className={classes.filterHeaderWrapper}>
        <ButtonGroup
            className={classes.filterButtons}
            justifyContent={'left'}
        >
            {filterableSourcesHeaderButtons.map( ( filterableSource, idx ) => {
                return (
                <ButtonOutline
                    key={idx}
                    id={`filter-button-${idx}`}
                    textColour={ThemeData.colours.black}
                    hoverTextColour={ThemeData.colours.white}
                    backgroundColour={ThemeData.colours.white}
                    hoverBackgroundColour={ThemeData.colours.primaryAccent}
                    borderColour={ThemeData.colours.black}
                    className={classes.filterButton}
                    isActive={checkIsActive(filterableSource.unitRelationDataType)}
                    popupWindow={data &&
                        <div className={classes.filterList}>
                            {filterableSource.filterType === ConstantData.unitFilterTypes.checkedList &&
                                <InputCheckedList
                                    className={"inputchecklist"}
                                    key={idx}
                                    data={data[filterableSource.unitRelationDataType]}
                                    listLabelField={filterableSource.dataListItemLabelField}
                                    filterType={filterableSource.unitRelationDataType}
                                    hooks={hooks}
                                    filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
                                    setFilterSettings={hooks.globalUnitsFilter.setFilter}
                                />}
                            {filterableSource.filterType === ConstantData.unitFilterTypes.range &&
                                <InputRangeSlider
                                    className={classes.filterRange}
                                    // label={filterableSource.label}
                                    filterType={filterableSource.unitField}
                                    min={filterableSource.rangeMinimumValue}
                                    max={filterableSource.rangeMaximumValue}
                                    step={filterableSource.rangeStep}
                                    displayedDataFormattingFunction={filterableSource.displayedDataFormattingFunction}
                                    filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
                                    setFilterSettings={hooks.globalUnitsFilter.setFilter}
                                    inputLabels={['min', 'max']}
                                    adornment={filterableSource.unitField === 'price' && <InputAdornment>$</InputAdornment>}
                                    isClearButtonVisible={true}
                                />
                            }
                        </div>
                    }
                    firstInColl={idx === 0 ? "true" : null}
                >
                    {calculateFilterButtonName(data[filterableSource.unitRelationDataType], filterableSource.unitRelationDataType, filterableSource.label, filterableSource.filterType)}
                    &nbsp;<FaCaretDown />
                </ButtonOutline>
                
            )} )}
            <ButtonOutline
                textColour={ThemeData.colours.black}
                hoverTextColour={ThemeData.colours.white}
                backgroundColour={ThemeData.colours.white}
                hoverBackgroundColour={ThemeData.colours.primaryAccent}
                borderColour={ThemeData.colours.white}
                className={classes.filterButton}
                onClick={() => props.setDoShowFilter(!props.doShowFilter)}
            >All filters</ButtonOutline>
            {!(filterableSourcesHeaderButtons.every((filterableSource) => !checkIsActive(filterableSource.unitRelationDataType))) &&
                <ButtonOutline
                    id="clear-filters-button"
                    textColour={ThemeData.colours.secondaryAccent}
                    textDecoration='underline'
                    hoverTextColour={ThemeData.colours.primaryAccent}
                    backgroundColour={ThemeData.colours.black}
                    hoverBackgroundColour={ThemeData.colours.black}
                    border='none'
                    onClick={() => hooks.globalUnitsFilter.resetFilter()}
                >Clear Filters</ButtonOutline>
            }
        </ButtonGroup>
    </div>
    )
}

export default FilterHeader;