import ButtonGroup from "./ButtonGroup"
import { createUseStyles } from "react-jss"
import ButtonCircle from "./ButtonCircle"
import {ReactComponent as NavArrowLeft} from "../img/nav_arrow_left.svg";
import {ReactComponent as NavArrowRight} from "../img/nav_arrow_right.svg";
import ThemeData from "data/ThemeData";
import { useState } from "react";

const styles = createUseStyles({
    carouselWrapper: {
        height: 150,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 45,
        padding: '0 40px'
    },
    buttonGroup: {
        justifyContent: 'left',
        paddingTop: 24,
    },
    leftContent: {
        height: '100%',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'end',
    },
    lineWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    line: {
        height:'3px',
        minWidth: 15,
        width: '100%',
        borderRadius: '0px',
        margin: '0 5px',
    },
    lineInactive: {
        backgroundColor: '#43434340'
    },
    lineActive: {
        backgroundColor: '#434343'
    }
})
export const ContentNavigationCarousel = ({tab, setTab, tabs}) => {
    const tabsWithNoImages = tabs.filter(i => !i.is_only_image_card || i.is_only_image_card === null);
    const orders = tabsWithNoImages.map(element => element.order);
    
    const [currentActiveElement, setElement] = useState(orders.indexOf(tab));
    const classes = styles({ tabs, tab });
    
    const renderLines = () => tabsWithNoImages.map((i) => <div className={`${i.order === tab ? classes.lineActive : classes.lineInactive} ${classes.line}`}></div>)
    
    let isActiveLeft = tab-1 === 0;
    let isActiveRight = currentActiveElement + 1 === tabsWithNoImages.length;
    return (
        <div className={classes.carouselWrapper} id="content-page-carousel">
            <div className={classes.leftContent}>
                <div className={classes.lineWrapper}>
                    {renderLines()}
                </div>
                <ButtonGroup className={classes.buttonGroup}>
                    <ButtonCircle
                        onClick={() =>{ setElement(currentActiveElement - 1); setTab(orders[currentActiveElement - 1]);}}
                        borderColor={isActiveLeft ?  ThemeData.colours.primaryAccentFade : ThemeData.colours.centraCream} 
                        disabled={currentActiveElement === 0}
                        > 
                            <NavArrowLeft fill={isActiveLeft ?  ThemeData.colours.primaryAccentFade : ThemeData.colours.centraCream} id="content-page-navigate-left"/>
                    </ButtonCircle>
                    <ButtonCircle 
                        onClick={() => { setElement(currentActiveElement + 1); setTab(orders[currentActiveElement + 1]); }}
                        borderColor={isActiveRight ? ThemeData.colours.primaryAccentFade : ThemeData.colours.centraCream} 
                        disabled={currentActiveElement + 1 === tabsWithNoImages.length}
                        > 
                            <NavArrowRight fill={isActiveRight ?  ThemeData.colours.primaryAccentFade : ThemeData.colours.centraCream} id="content-page-navigate-right"/>
                    </ButtonCircle>
                </ButtonGroup>
            </div>
        </div>
    )
}