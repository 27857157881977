import React from "react";

import {
} from "react-router-dom";
import { createUseStyles } from "react-jss";

import {
	useQuery,
} from "@apollo/client";

import {
	motion,
	useCycle,
} from "framer-motion";


import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";
import HeaderContextData from "../data/HeaderContextData";
import HeaderComponents from "./HeaderComponents";
import TopMenu from "./TopMenu";
import mainMenuOpenIcon from "../img/main_menu_open.svg";
import mainMenuCloseIcon from "../img/main_menu_close.svg";


export const useStyles = createUseStyles(
	{
		mainMenuButton: {
			flex: '0 1 auto',

			width: 100,
			height: 100,

			cursor: 'pointer',

			background: 'no-repeat center',

			fontFamily: ThemeData.fonts.title,
		},
		openMainMenuButton: {
			backgroundImage: `url( ${mainMenuOpenIcon} )`,
		},
		closeMainMenuButton: {
			backgroundImage: `url( ${mainMenuCloseIcon} )`,
		},
		closeMainMenuText: {
			...ThemeData.typography.nameplate,

			cursor: 'pointer',
		},
		closeMainMenuTextButton: {
			flex: '1 0 auto',
			height: 100,
			width: 350,

			display: 'flex',
			alignItems: 'center',
		},
		mainMenu: {
			flexDirection: 'column',
			justifyContent: 'space-between',

			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: '100%',

			display: 'none',

			'.isOpen &': {
				display: 'block',
			},

			// backgroundColor: ThemeData.colours.primaryBg,
		},
		mainMenuContent: {
			marginTop: 100,
			backgroundColor: ThemeData.colours.primaryBg,
		},
	},
	{
		name: "HeaderContainer",
	}
);

const variants = {
	open: {
		y: 0,
		zIndex: 5,
		display: 'block',
	},
	closed: {
		y: "-100vh",
		display: 'block',
		transitionEnd: { zIndex: -2, display: 'none', },
	}
}


const HeaderContainer = ( { ...props } ) => {

	const [isOpen, toggleOpen] = useCycle( false, true );
	const classes = useStyles();

	const { loading, error, data } = useQuery( QueryData.navQuery );

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log('hco', error.message )}</p>

	return (
		<header>
			<HeaderComponents
				headerContext={HeaderContextData.siteHeaderContext}
				toggleIsSavedHomesSlideoutOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
				toggleIsRegisterOpen={() => props.toggleIsRegisterOpen()}
				backgroundColor={ThemeData.colours.primaryBgTransparent}
			>
				{isOpen ?
					<div className={`${classes.closeMainMenuTextButton}`}>
						<div className={`${classes.mainMenuButton} ${classes.closeMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} />
						<div className={`${classes.closeMainMenuText}`} onClick={() => toggleOpen( isOpen => !isOpen )}>
							<p>Close Menu</p>
						</div>
					</div>
					:
					<div className={`${classes.mainMenuButton} ${classes.openMainMenuButton}`} onClick={() => toggleOpen( isOpen => !isOpen )} />
				}
			</HeaderComponents>
			<motion.div
				className={classes.mainMenu}
				initial={false}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ type: 'spring', bounce: .25, duration: .5 }}
			>
				<div className={`${classes.mainMenuContent}`}>
					<TopMenu
						dataNav={data}
						isOpen={isOpen}
						/>
				</div>
			</motion.div>
		</header>
	)

};


export default HeaderContainer;