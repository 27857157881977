import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			margin: '0 0 10px 0',

			color: props.color,

			fontFamily: ThemeData.fonts.title,
			fontSize: props.fontSize,
			textAlign: 'left',

		} ),
	},
	{
		name: "UnitInfoPlanNameTitle",
	}
)



// data contains a Unit
const UnitInfoPlanNameTitle = ( { data, className, ...props } ) => {

	const classes = useStyles( props );

	// console.log( 'uipnt', data );

	return (
		<div className={`${className} ${classes.wrapper}`}>
			{data && data.unit_plan_type.name}
			{props.doShowUnitNumber && ` | Unit ${data && data.unit_number}`}
		</div>
	);
}



UnitInfoPlanNameTitle.defaultProps = {

	color: ThemeData.colours.primaryBg,

	fontFamily: ThemeData.fonts.titleSub,
	fontSize: 36,

	doShowUnitNumber: true,

}



export default UnitInfoPlanNameTitle;