import {ReactComponent as BookmarkImageSaved} from '../img/book_mark_saved.svg';
import { ReactComponent as BookmarkImage} from '../img/book_mark.svg';
import { createUseStyles } from 'react-jss';
import ThemeData from 'data/ThemeData';
import { useState } from 'react';

const useStyles = createUseStyles({
    bookmark: {
        height: props => props.height,
        width: props => props.width,
        margin: props => props.margin,
        cursor: 'pointer',
    },
    bookmarkSaved: {
        height: props => props.height,
        width: props => props.width,
        margin: props => props.margin,
        cursor: 'pointer'
    },
})


export const BookMarkButton = ({id, hooks, dataUnit, height, width, margin}) => {
    const classes = useStyles({height, width, margin})
    const [hover, toggleOnHover] = useState(false);
    return dataUnit && hooks.savedHomesIncludes( dataUnit.id ) ?
        <BookmarkImageSaved 
            id={`book-mark-button-active`} 
            fill={ThemeData.colours.secondaryAccent} 
            className={classes.bookmarkSaved} 
            onClick={() => {hooks.toggleSavedHome( dataUnit.id ); toggleOnHover(false)}}
        />
        :
        <BookmarkImage
            id={`book-mark-button`} 
            fill={hover ? ThemeData.colours.primaryAccent : 'none'} 
            stroke={ThemeData.colours.secondaryAccent} 
            className={classes.bookmark} 
            onClick={() => {hooks.toggleSavedHome( dataUnit.id ); toggleOnHover(false)}}
            onMouseOver={() => toggleOnHover(true)}
            onMouseLeave={() => toggleOnHover(false)}
        />
    
}