import { AverageHexStrings } from "util/colourFunctions";


let ThemeData = {
	colours: {},
	fonts: {},
	util: {},
};

ThemeData.util = {
	mainBackgroundImage: 'https://storage.googleapis.com/pxa-arcane-prod/Main_Image_f40bb8835a/Main_Image_f40bb8835a.png'
}

ThemeData.util = {
	...ThemeData.util,
	mainBackgroundImage: ThemeData.util.mainBackgroundImage,
}


ThemeData.colours = {
	clear: "rgb( 255, 255, 255, 0 )",
	clay: "#4B656A",
	clay25: "rgb( 75, 101, 106, .25 )",
	clay50: "rgb( 75, 101, 106, .50 )",
	clay75: "rgb( 75, 101, 106, .75 )",
	clay08: "rgb( 75, 101, 106, .08 )",
	clay05: "rgba(73, 101, 107, 0.05)",
	// clay25: "rgba(206, 144, 101, .25)",
	// clay50: "rgba(206, 144, 101, .5)",
	// clay75: "rgba(206, 144, 101, .75)",
	// clay08: "rgba(206, 144, 101, .08)",
	// eggshellTextBg: "#EEEDE6",
	eggshellTextBg: "#000000",
	goldText: "#DDAE5E",
	//goldText: "#4B656A",
	// green: "#49656B",
	// green: "rgb( 163, 208, 211 )",
	grey: "#434343",
	grey25: "rgba( 67, 67, 67, .25 )",
	grey10: "rgba( 67, 67, 67, .10 )",
	white90: "rgb( 255, 255, 255, .9 )",
	white75: "rgb( 255, 255, 255, .75 )",
	white50: "rgb( 255, 255, 255, .4 )",
	white25: "rgb( 255, 255, 255, .25 )",
	white20: "rgb( 255, 255, 255, .2 )",
	white05: "rgb( 255, 255, 255, .05 )",
	white: "#FFFFFF",
	notWhite: "#F9F9F9",
	black: "rgba( 0, 0, 0, 1 )",
	blackBg75: "rgba( 0, 0, 0, .75 )",
	blackBg50: "rgba( 0, 0, 0, .5 )",
	blackBg40: "rgba( 0, 0, 0, .4 )",
	blackBg25: "rgba( 0, 0, 0, .25 )",
	blackBg20: "rgba( 0, 0, 0, .2 )",
	blackBg15: "rgba( 0, 0, 0, .15 )",
	blackBg10: "rgba( 0, 0, 0, .1 )",
	blackBg5: "rgba( 0, 0, 0, .05 )",

	aaeForestGreen: "#49656B",
	aaeForestGreen25: "#49656B40",
	aaeClay: "#CE9065",
	aaeGold: "#DDAE5E",
	
	kindredSkyBlue: "rgb( 163, 208, 211 )",
	kindredGreen: "#4B656A",
	
	neuVibrantDarkBlue: "#012932",
	neuDarkBlue: "#0D2831",
	neuDarkBlueFade: "#0D283180",
	neuForestGreen: "#303E1B",
	neuVibrantGreen: "#ADA743",
	neuVibrantGreenFade: "#ADA74326",
	neuDarkGreen: "#435B5F",
	neuClay: "#CE9065",
	neuClayFade: "#CE906540",
	neuPaleOak: "#DFDACF",
	neuDeepGrey: "#434343",
	neuLightTan: "#EEEDE6",
	neuGray: "#49656b0d",

	centraGold: "#B06F44",
	centraBlack: "#FFFFFF",
	centraSteel: "#808285",
	centraBlue: "#54729E",
	centraOrange: "#F26522",
	centraCream: "#E8E4D8",
	
	arcaneBurntOrange: "#F15025",
	arcaneDeepBlue: "#232DD9",
	arcaneSteelBlue: "#B5CFDE",
	arcaneEmeraldGreen: '#72DA8C',
	arcanePaleBlue: "#515591"
};


ThemeData.colours = {
	...ThemeData.colours,

	primaryBg: ThemeData.colours.black,
	primaryBgTransparent: ThemeData.colours.blackBg75,
	primaryHeaderBg: ThemeData.colours.black,
	secondaryBg: ThemeData.colours.neuPaleOak,
	secondaryBgHighContrast: ThemeData.colours.neuLightTan,
	primaryAccent: ThemeData.colours.centraSteel,
	secondaryAccent: ThemeData.colours.centraCream,

	primaryAccentFade: ThemeData.colours.neuVibrantGreenFade,

	transparentDarkening: ThemeData.colours.blackBg75,

	headerFooterBg: ThemeData.colours.blackBg50,

	primaryFont: ThemeData.colours.white,
	primaryFontHighContrast: ThemeData.colours.neuLightTan,
	primaryFontHighestContrast: ThemeData.colours.white,
	primaryTitleFont: ThemeData.colours.neuPaleOak,

	secondaryFont: ThemeData.colours.white,
	secondaryTitleFont: ThemeData.colours.white,
	secondarySubTitleFont: ThemeData.colours.neuDeepGrey,

	mainBorder: ThemeData.colours.white50,
	mainBorderLight: ThemeData.colours.white05,
	mainBorderDarkOnLight: ThemeData.colours.blackBg50,

	lightBorder: ThemeData.colours.white25,
	lightBorderDarkOnLight: ThemeData.colours.blackBg25,
	lighterBorderDarkOnLight: ThemeData.colours.grey25,

	rowColoursLight: [
		ThemeData.colours.white,
		ThemeData.colours.notWhite,
	]

}

ThemeData.colours = {
	...ThemeData.colours,
	// Cards
	cardBg: ThemeData.colours.white,
	cardFont: ThemeData.colours.secondaryFont,
	cardTitleFont: ThemeData.colours.secondaryFont,
	cardSubTitleFont: ThemeData.colours.secondaryTitleFont,
}



ThemeData.colours = {
	...ThemeData.colours,
	primarySelected: ThemeData.colours.neuClay,
	primaryUnselected: ThemeData.colours.primaryFont,
}

ThemeData.colours = {
	...ThemeData.colours,
	primarySelectHover: AverageHexStrings( ThemeData.colours.primarySelected, ThemeData.colours.primaryUnselected ),
}


// const opacitiesToCreate = {
// 	25: .25,
// 	50: .5,
// 	75: .75,
// 	08: .08,
// }

// ThemeData.colours = {
// 	...ThemeData.colours,
// 	opacitiesToCreate.map( opacityObj => )
// }

ThemeData.colours = {
	...ThemeData.colours,

	thinBorder: ThemeData.colours.mainBorder,
	thinBorderDarkOnLight: ThemeData.colours.mainBorderDarkOnLight,
	paneBorders: `1px ${ThemeData.colours.mainBorder} solid`,
	paneBordersLight: `1px ${ThemeData.colours.white90} solid`,
	navCurrentUnderline: ThemeData.colours.arcaneSteelBlue,
}


ThemeData.fonts = {
	brotherRegular: "Brother1816 Regular, sans-serif",
	brotherMedium: "Brother1816 Medium, sans-serif",
	lausanne: "Lausanne, sans-serif",
};

ThemeData.fonts = {
	...ThemeData.fonts,

	title: ThemeData.fonts.brotherRegular,
	titleSub: ThemeData.fonts.lausanne,
	copy: ThemeData.fonts.brotherRegular,
}


ThemeData.typography = {
	nameplate: {
		color: ThemeData.colours.primaryFont,
		fontFamily: ThemeData.fonts.title,
		textTransform: 'uppercase',
	},
	nameplateNoColor: {
		fontFamily: ThemeData.fonts.title,
		letterSpacing: '.15em',
		textTransform: 'uppercase',
	},
}

ThemeData.typography = {
	...ThemeData.typography,

	contentCardTitles: {
		fontFamily: ThemeData.fonts.title,
		fontSize: 32,
		letterSpacing: 0,
		letterHeight: '1.1em',
	},
}

ThemeData.ux = {
	fillToLeft: {
		transition: 'all 0.66s ease',

		background: `linear-gradient( to right, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.centraBlue} 50% )`,
		backgroundSize: 'calc( 200% + 2px ) 100%',
		backgroundPosition: 'left bottom',

		'&:hover': {
			backgroundPosition: 'right bottom',
		},
	},
	fillToRight: {
		transition: 'all 0.66s ease',

		background: `linear-gradient( to left, rgb( 0, 0, 0, 0 ) 50%, ${ThemeData.colours.centraBlue} 50% )`,
		backgroundSize: 'calc( 200% + 2px ) 100%',
		backgroundPosition: 'right bottom',

		'&:hover': {
			backgroundPosition: 'left bottom',
		},
	},
};

ThemeData.styles = {
	hoverPaneBoxShadow: {
		boxShadow: '0px 2px 35px rgb(0 0 0 / 10%)',
	},
	hrBoxShadow: {
		boxShadow: `0 10px 20px ${ThemeData.colours.blackBg15}`,
	},
}

ThemeData.styles = {
	...ThemeData.styles,
	hoverCard: {
		color: ThemeData.colours.cardFont,
		backgroundColor: ThemeData.colours.cardBg,
		// backgroundColor: ThemeData.colours.primaryBg,

		// border: `1px solid ${ThemeData.colours.eggshellTextBg}`,
		boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
		borderRadius: 4,
	},
	hideScrollbar: {
		// Hide scrollbar
		'-ms-overflow-style': 'none',
		scrollbarWidth: 'none',

		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	linkColourRemoval: {
		'& a, & a:visited': {
			color: ThemeData.colours.primaryFont,
			textDecoration: 'none',
		},
	},
	roundedCorners: {
		borderRadius: 9,
	},
}

// Scrollbars
ThemeData.styles = {
	...ThemeData.styles,

	thinScrollbarNoColour: {
		'&::-webkit-scrollbar': {
			width: '7px',
			height: '7px',
			// paddingTop: 10,
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 20,
		}
	},
}
ThemeData.styles = {
	...ThemeData.styles,

	thinScrollbarDarkOnLight: {
		...ThemeData.styles.thinScrollbarNoColour,

		'&::-webkit-scrollbar-track': {
			...ThemeData.styles.thinScrollbarNoColour['&::-webkit-scrollbar-track'],
			backgroundColor: ThemeData.colours.blackBg5,
		},
		'&::-webkit-scrollbar-thumb': {
			...ThemeData.styles.thinScrollbarNoColour['&::-webkit-scrollbar-thumb'],
			backgroundColor: ThemeData.colours.blackBg20,
		}
	},
	thinScrollbarLightOnDark: {
		...ThemeData.styles.thinScrollbarNoColour,

		'&::-webkit-scrollbar-track': {
			...ThemeData.styles.thinScrollbarNoColour['&::-webkit-scrollbar-track'],
			backgroundColor: ThemeData.colours.white05,
		},
		'&::-webkit-scrollbar-thumb': {
			...ThemeData.styles.thinScrollbarNoColour['&::-webkit-scrollbar-thumb'],
			backgroundColor: ThemeData.colours.white20,
		}
	},
}
ThemeData.styles = {
	...ThemeData.styles,

	thinScrollbar: {
		...ThemeData.styles.thinScrollbarDarkOnLight,
	},
}



ThemeData.anim = {
	defaultOpacityFade: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
		transition: {
			type: "linear",
			duration: .25,
		},
	},
}



export default ThemeData;
