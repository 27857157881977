import UtilFcns from "util/UtilFcns";


import neighGeo from 'data/centra-neighbourhood.json';
import ConstantData from "./ConstantData";



let SiteConfigData = {
	MapBox: {
		// jj@lng acct
		AccessToken: 'pk.eyJ1IjoibG5nc3R1ZGlvcyIsImEiOiJja3I2anhhaHAyaThoMnBzNjFqbGQ3MmxhIn0.Spy65NSiUTz-vo1GJqg9hA',
		Center: {
			lng: -122.8444671,
			lat: 49.18293434,
			zoom: 13.44,
		},
		StyleURL: 'mapbox://styles/lngstudios/ckvmk18ws2kj214qshc3nxy6u',
		NeighbourhoodGeoJSON: neighGeo,
	},
	unitTableItemColumns: [
		{
			columnName: 'Type',
			relationName: 'unit_plan_type',
			fieldName: 'name',
			displayedDataFormattingFunction: value => value,
		},
		{
			columnName: 'Rooms',
			relationName: 'unit_home_type',
			fieldName: 'name',
			displayedDataFormattingFunction: value => value,
		},
		{
			columnName: 'Building',
			relationName: 'unit_building',
			fieldName: 'name',
			displayedDataFormattingFunction: value => value,
		},
		{
			columnName: 'Price',
			relationName: '',
			fieldName: 'price',
			displayedDataFormattingFunction: ConstantData.dataFormattingFunctions.prices,
		},
		{
			columnName: 'Size',
			relationName: '',
			fieldName: 'interior_sqft',
			displayedDataFormattingFunction: value => value,
		},
		{
			columnName: 'Availability',
			relationName: 'unit_availability_state',
			fieldName: 'name',
			displayedDataFormattingFunction: value => value,
		},
	],
	Floorplans: {
		// unused
		orderBy: {
			relationName: 'unit_plan_type',
			fieldName: 'name',
			isDirectionAscending: true,
		},
		// used
		orderByPredicate:
			( unitA, unitB ) =>
				unitA.unit_plan_type.name === unitB.unit_plan_type.name ?
					unitA.unit_number - unitB.unit_number :
					unitA.unit_plan_type.name.localeCompare( unitB.unit_plan_type.name )
		,
		// unused
		prevOrderByPredicate:
			( unitA, unitB ) =>
				( UtilFcns.parseFloatSafe( unitA.interior_sqft ) + UtilFcns.parseFloatSafe( unitA.exterior_sqft ) ) -
				( UtilFcns.parseFloatSafe( unitB.interior_sqft ) + UtilFcns.parseFloatSafe( unitB.exterior_sqft ) )
		,
	},
};



export default SiteConfigData;