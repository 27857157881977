import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


// import ThemeData from "data/ThemeData";



const useStyles = createUseStyles(
	{
		wrapper: {
			// padding: 20,

			textAlign: 'left',
		},
	},
	{
		name: "UnitInfoLine",
	}
)



const UnitInfoLine = ( { data, className, ...props } ) => {

	const classes = useStyles();


	return (
		<div className={`${classes.wrapper} ${className}`}>
			{data && data.info_line_override}
			{data && !data.info_line_override &&
				<>
					{props.doShowFloor && data && data.unit_floor && data.unit_floor.number && `Floor ${data.unit_floor.number} | `}
					{props.doShowUnitNumber && data && `Unit ${data.unit_number}`}
					{/* {props.doShowHomeType && data && `${data.unit_home_type.name} | `} */}
					{/* {props.doShowBaths && data && data.baths && `${data.baths} Bath | `}
					{props.doShowIntSqft && data && `${data.interior_sqft} sqft`}
					{props.doShowExtSqft && data && `${data.exterior_sqft} ext. sqft`} */}
				</>
			}
		</div>
	);
}



UnitInfoLine.defaultProps = {
	doShowFloor: false,
	doShowUnitNumber: false,
	doShowHomeType: true,
	doShowBaths: true,
	doShowIntSqft: true,
	doShowExtSqft: false,
}



export default UnitInfoLine;