import React, {
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";

import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";

import ButtonGroup from "components/ButtonGroup";
import ButtonCircle from "components/ButtonCircle";
import ButtonOutline from "components/ButtonOutline";


import xWhiteSmall from "img/x_white_small.svg";
import UnitInfoIconList from "./UnitInfoIconList";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			width: 575,

			...ThemeData.styles.hoverCard,
			'@media (min-width: 3800px)':{
				minHeight: 800,
				maxHeight: 800,
			}
		
		},
		contentContainer: {
			minHeight: 530,
			maxHeight: 530,
			display: 'flex',
			flexDirection: 'column',
			boxSizing: 'border-box',
			padding: 20,
			textAlign: 'left',
			'@media (max-width: 1200px)':{
				padding: 15
			},
			'@media (min-width: 1190px)':{
				minHeight: 590,
				maxHeight: 590,
			},
			
			
		},
		hr: {
			width: '100%',

			marginTop: 35,

			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
			'@media (max-width: 1200px)':{
				marginTop: 10
			}
		},
		removeHomeButton: {
			position: 'absolute',
			top: 20,
			right: 20,

			width: 48,
		},
		floorplanContainer: {
			flex: '0 0 auto',

			// width: 105,
			height: 300,
			'@media (max-width: 1200px)':{
				height: 275
			},
			marginRight: 25,
			borderRadius: 4,
		},
		floorplanImg: {
			height: '100%',
			width: '100%',

			objectFit: 'contain',
		},
		planButtonsContainer: {
			marginTop: 10,
			'@media (max-width: 1200px)':{
				marginTop: 0
			}
		},
		floorKeyPlanButtons: {
			borderRadius: 50,
			'@media (min-width: 3800px)':{
				fontSize: '24px',
				height: '60px'
			},
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,

		},
		unitInfoLine: {
			color: ThemeData.colours.black,

			fontSize: 16,
			'@media (min-width: 3800px)':{
				fontSize: '36px',
				height: '70px'
			},
		},
		unitInfoIconList: {
			color: ThemeData.colours.black,
			
		},
		viewDetailButtonArea: {
			paddingTop: 10,
		},
	},
	{
		name: "UnitDetailCompareCard",
	}
)


// data == a single Unit
const UnitDetailCompareCard = ( { dataUnit, ...props } ) => {

	const classes = useStyles();


	const [doShowFloorplanImg, setDoShowFloorplanImg] = useState( 'floorPlan' );
	// const toggleDoShowFloorplanImg = () => setDoShowFloorplanImg( !doShowFloorplanImg );

	const toggleDoShowFloorplanImg = (currentPlan) => {
		switch(currentPlan) {
			case 'floorPlan':
				setDoShowFloorplanImg('floorPlan');
				break;
			case 'keyPlan':
				setDoShowFloorplanImg('keyPlan');
				break;
			default:
				break;
		}
	}

	// console.log(dataUnit);



	// console.log( 'dataUnit', dataUnit );

	return (
		<div className={`${classes.wrapper} ${props.className}`}>
			<div className={classes.contentContainer}>
				<div className={classes.removeHomeButton}>
					<ButtonCircle
						src={xWhiteSmall}
						alt="Remove This Saved Home"
						widthHeight={46}
						iconWidthHeight={26}
						borderColour={ThemeData.colours.white}
						textColour={ThemeData.colours.primaryAccent25}
						backgroundColour={ThemeData.colours.primaryBg}
						onClick={() => props.toggleSavedHome()}
					/>
				</div>
				<div className={classes.info}>
					<UnitInfoLine
						data={dataUnit}
						className={classes.unitInfoLine}
						doShowFloor={true}
						doShowUnitNumber={true}
						doShowHomeType={false}
						doShowBaths={false}
						doShowIntSqft={false}
					/>
				</div>
				<div className={classes.floorplanContainer}>
					{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
						dataUnit.floorplan_image.url &&
						<>
							{doShowFloorplanImg === 'floorPlan' && dataUnit.floorplan_image && 
								<img
									className={classes.floorplanImg}
									src={NetData.imgUri( dataUnit && dataUnit.floorplan_image.url )}
									alt={dataUnit && dataUnit.floorplan_image.alternativeText} />
							}
							{doShowFloorplanImg === 'keyPlan' && dataUnit.keyplan_image && 
								<img
									className={classes.floorplanImg}
									src={NetData.imgUri( dataUnit && dataUnit.keyplan_image.url )}
									alt={dataUnit && dataUnit.keyplan_image.alternativeText} />
							}
						</>
					}
				</div>
				<ButtonGroup
					justifyContent={'center'}
				>
					{dataUnit && dataUnit.floorplan_image &&
						<div className={classes.planButtonsContainer}>
								<ButtonOutline
									borderColour={ThemeData.colours.grey25}
									className={classes.floorKeyPlanButtons}
									textColour={ThemeData.colours.primaryAccent}
									backgroundColour={ThemeData.colours.white}
									hoverTextColour={ThemeData.colours.white}
									hoverBackgroundColour={ThemeData.colours.primaryAccent}
									firstInColl
									onClick={() => toggleDoShowFloorplanImg('floorPlan')}
									isActive={doShowFloorplanImg === 'floorPlan'}
								>
									Floor Plan
								</ButtonOutline>
						</div>
					}
					{dataUnit && dataUnit.keyplan_image &&
						<div className={classes.planButtonsContainer}>
								<ButtonOutline
									borderColour={ThemeData.colours.grey25}
									className={classes.floorKeyPlanButtons}
									textColour={ThemeData.colours.primaryAccent}
									backgroundColour={ThemeData.colours.white}
									hoverTextColour={ThemeData.colours.white}
									hoverBackgroundColour={ThemeData.colours.primaryAccent}
									onClick={() => toggleDoShowFloorplanImg('keyPlan')}
									isActive={doShowFloorplanImg === 'keyPlan'}
								>
									Key Plan
								</ButtonOutline>
						</div>
					}
				</ButtonGroup>
				<div className={classes.hr} />
				<UnitInfoIconList
					className={classes.unitInfoIconList}
					dataUnit={dataUnit}
					marginTopItemsRow={25}
					doShowFacingItem={false}
					
				/>
			</div>
		</div>
	);
}



export default UnitDetailCompareCard;