import React, {
	// useState,
	// useRef,
} from "react";

// import {
// 	useQuery,
// 	gql,
// } from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import HeaderSimple from "./HeaderSimple";


import ThemeData from "../data/ThemeData";
// import QueryData from "../data/QueryData";
// import NetData from "../data/NetData";


import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import viewStudy722 from "../img/viewStudies/722-MaxSize.jpg";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'absolute',
			top: 100,
			left: 0,
			backgroundColor: ThemeData.colours.white,
			width: '100%',
			height: 'calc( 100vh - 100px )',
		},
		header: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			backgroundColor: ThemeData.colours.primaryHeaderBg,
			// backgroundColor: ThemeData.colours.white50,

			zIndex: 2,
		},
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: '#FFF',

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		viewStudy: {
			height: 'calc( 100% )',
			width: '100%',
		},
		viewPhoto: {
			width: '100%',
			display: 'block',
			height: '100%',
		}
	},
	{
		name: "ViewStudy",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 9999,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}

function RenderViewStudy( classes, error, isOpen, toggleViewStudyFcn, viewUrl, backLocation ) {
	const panImage = React.useRef( null );

	//TODO: Implement feature flag for image or iframe view image 
	return (
		<motion.div
			id="view-study"
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: 'linear',
				duration: .5,
			}}
		>
			<div className={classes.viewStudy}>
				<img className={classes.viewPhoto}
					id= "view-id"
					title="View Study"
					ref={panImage}
					frameBorder= "0"
					alt= ""
					src={viewUrl}
				/>
			</div>
		</motion.div>
	);
}


const ViewStudy = ( { isOpen, toggleViewStudyFcn, viewUrl, backLocation } ) => {

	const classes = useStyles();

	return RenderViewStudy( classes, null, isOpen, toggleViewStudyFcn, viewUrl, backLocation );

};



export default ViewStudy;